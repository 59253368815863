/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHref, useParams } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { Option } from './components/NonProfitSearch';
import { PageFooter } from './components/page-footer';
import { ReviewButtons } from './components/ReviewButtons';
import { FeedbackParams, TopSection } from './Feedback';
import { getFeedback } from './profiles';
import styles from './styles/Home.module.css';
import { AssetInterface, FeedbackInterface, LocationInterface, OrganizationInterface, OrganizationStyling, PractitionerInterface, RecipientInterface, ReputationServiceInterface } from './types';

export const FeedbackReviews: FC = () => {
  const { feedbackId = '62a24459ffecdb6fdbe7ccfc' } = useParams<FeedbackParams>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ feedback, setFeedback ] = useState<FeedbackInterface>();
  const [ recipient, setRecipient ] = useState<RecipientInterface>();
  const [ practitioner, setPractitioner ] = useState<PractitionerInterface>();
  const [ location, setLocation ] = useState<LocationInterface>();
  const [ organization, setOrganization ] = useState<OrganizationInterface>();
  const [ assets, setAssets ] = useState<AssetInterface[]>( [] );
  const [ reputationServices, setReputationServices ] = useState<ReputationServiceInterface[]>( [] );
  // const [ countryCode, setCountryCode ] = useState( 'US' );
  const [ selectedOption, setSelectedOption ] = useState<Option | null>( null );

  useEffect( () => {
    ( async () => {
      if( !feedbackId || isLoaded ) return;
      const { feedback, recipient, practitioner, location, organization, assets,
        selectedOption,
        reputationServices, // , countryCode
      } = ( await getFeedback( feedbackId ) ) || {};
      if( !recipient ) return;
      setIsLoaded( true );
      setFeedback( feedback );
      setRecipient( recipient );
      setPractitioner( practitioner );
      setLocation( location );
      setOrganization( organization )
      setAssets( assets || [] );
      setReputationServices( reputationServices || [] );
      // setCountryCode( countryCode || 'US' );
      setSelectedOption( selectedOption ?? null );
    } )()
  }, [ feedbackId ] );

  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader, nextPage, disableCharities } = useMemo( () => {
    if( !organization || !practitioner ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, disableCharities } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    const nextPage = practitioner.isInternalReviewOnly ? '../survey' : '../reviews';
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, nextPage, disableCharities };
  }, [ assets, organization, practitioner ] );

  const hrefPrevious = disableCharities ? useHref( '..', { relative: 'path' } ) : useHref( '../nonprofits', { relative: 'path' } );

  if( !( recipient && organization && location && practitioner && feedback ) ) return null;

  return (
    <Box sx={{
      padding: { xs: 0, md: '0 1.5rem' },
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: { xs: '1rem', md: '1.2rem' },
      backgroundColor: '#f9fafb',
      fontFamily: fontBody,

    }}>

      <main className={styles.main} style={{ fontFamily: fontBody }}>
        <Helmet>
          <title>Feedback Reviews - {location.name}</title>
          <meta name="description" content={`Feedback on ${ location.name }`} />
          <link rel="icon" href="/favicon.ico" />
        </Helmet>

        <TopSection {...{ location, organization, fontHeader, colorPrimary, isXSmall }} />


        <Grid item key="content">
          <Typography variant='h6' className={styles.description}
            sx={{ margin: '0.5em 0' }}
          >
            LEAVE A REVIEW
          </Typography>

          <p>
            We encourage you to post a review on one or more of these independent sites. Clicking will open a new browser tab. Come back here to leave a review on another.            &nbsp;
            {selectedOption &&
              <>
                As you do, we will make a donation to:
              </>
            }
          </p>
          {selectedOption &&
            <Box sx={{ margin: '1em auto', textAlign: 'center' }} >
              <Box sx={{ width: 'fit-content', margin: 'auto' }}>
                <img
                  src={selectedOption.logo_url}
                  alt={`${ selectedOption.name } logo`}
                  style={{
                    maxWidth: '120px',
                    maxHeight: '120px',
                  }}

                />
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {selectedOption.name}
              </Typography>
            </Box>
          }

          <ReviewButtons subject={location} feedback={feedback} reputationServices={reputationServices} />

          {practitioner.isExternalReviewEnabled &&

            <ReviewButtons subject={practitioner} feedback={feedback} reputationServices={reputationServices} />
          }
          {/* <p>
              Clicking on any of these card will open a new browser tab. Come back here to leave a review on another.
              </p>
            */}
          <Grid item container key="nextButton" justifyContent="flex-start" sx={{ marginTop: '2em' }}>
            {!isXSmall &&
              <Button variant="outlined" size="large" href={hrefPrevious} fullWidth={isXSmall}>
                Previous
              </Button>
            }
          </Grid>

        </Grid>
      </main>

      <PageFooter organization={organization} />

    </Box>
  )
}

