/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHref, useParams } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import NonProfitSearch, { Option } from './components/NonProfitSearch';
import { PageFooter } from './components/page-footer';
import { FeedbackParams, TopSection } from './Feedback';
import { getFeedback } from './profiles';
import styles from './styles/Home.module.css';
import { AssetInterface, FeedbackInterface, LocationInterface, OrganizationInterface, OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';

export const FeedbackNonProfits: FC = () => {
  const { feedbackId = '62a24459ffecdb6fdbe7ccfc' } = useParams<FeedbackParams>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const hrefReviews = useHref( '../reviews', { relative: 'path' } );
  const hrefSurvey = useHref( '../survey', { relative: 'path' } );
  const hrefPrevious = useHref( '..', { relative: 'path' } );

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ feedback, setFeedback ] = useState<FeedbackInterface>();
  const [ recipient, setRecipient ] = useState<RecipientInterface>();
  const [ practitioner, setPractitioner ] = useState<PractitionerInterface>();
  const [ location, setLocation ] = useState<LocationInterface>();
  const [ organization, setOrganization ] = useState<OrganizationInterface>();
  const [ assets, setAssets ] = useState<AssetInterface[]>( [] );
  // const [ reputationServices, setReputationServices ] = useState<ReputationServiceInterface[]>( [] );
  // const [ countryCode, setCountryCode ] = useState( 'US' );
  const [ selected, setSelected ] = useState<Option | null>( null );

  useEffect( () => {
    ( async () => {
      if( !feedbackId || isLoaded ) return;
      const { feedback, recipient, practitioner, location, organization, assets,
        selectedOption,
        //reputationServices, countryCode
      } = ( await getFeedback( feedbackId ) ) || {};
      if( !recipient ) return;
      setIsLoaded( true );
      setFeedback( feedback );
      setRecipient( recipient );
      setPractitioner( practitioner );
      setLocation( location );
      setOrganization( organization )
      setAssets( assets || [] );
      // setReputationServices( reputationServices || [] );
      // setCountryCode( countryCode || 'US' );
      setSelected( selectedOption ?? null );
    } )()
  }, [ feedbackId ] );

  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader, nextPage } = useMemo( () => {
    if( !organization || !practitioner ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    const nextPage = practitioner.isInternalReviewOnly ? hrefSurvey : hrefReviews;
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, nextPage };
  }, [ assets, organization, practitioner ] );


  if( !( recipient && organization && location && practitioner && feedback ) ) return null;

  return (
    <Box sx={{
      padding: { xs: 0, md: '0 1.5rem' },
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: { xs: '1rem', md: '1.2rem' },
      backgroundColor: '#f9fafb',
      fontFamily: fontBody,

    }}>
      <Helmet>
        <title>Feedback Charities - {location.name}</title>
        <meta name="description" content={`Feedback on ${ location.name }`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main className={styles.main} style={{ fontFamily: fontBody }}>

        <TopSection {...{ location, organization, fontHeader, colorPrimary, isXSmall }} />

        <Grid item key="content">
          <Typography variant='h6' className={styles.description}
            sx={{ margin: '0.5em 0' }}
          >
            SELECT YOUR CHARITY
          </Typography>

          <p>
            We have partnered with <Link href="https://www.pledge.to/impact" target="_blank" rel="noopener">Pledge</Link>  to provide you
            with access to over 20,000 charities.
            Select a top charity or search for your preferred charity below or skip directly to the evaluation.
          </p>


          <NonProfitSearch token={feedback.id} onChange={setSelected} initialValue={selected || undefined} />

          <Grid item container key="nextButton" justifyContent="space-between" sx={{ marginTop: '2em' }}>
            {!isXSmall &&
              <Button variant="outlined" size="large" href={hrefPrevious} fullWidth={isXSmall}>
                Previous
              </Button>
            }
            <Button variant="contained" size="large" href={nextPage} color="success" fullWidth={isXSmall}>
              {selected ? 'Next' : 'Skip'}
            </Button>
          </Grid>
        </Grid>
      </main>

      <PageFooter organization={organization} />

    </Box>
  )
}


