/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { ArrowBackIosNew } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHref, useParams } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { NonProfitTicker } from './components/NonProfitTicker';
import { PageFooter } from './components/page-footer';
import { getFeedback } from './profiles';
import styles from './styles/Home.module.css';
import { AssetInterface, LocationInterface, OrganizationInterface, OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';

export type FeedbackParams = { feedbackId: string };

export const Feedback: FC = () => {
  const { feedbackId = '62a24459ffecdb6fdbe7ccfc' } = useParams<FeedbackParams>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const hrefReviews = useHref( '../reviews', { relative: 'path' } );

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ recipient, setRecipient ] = useState<RecipientInterface>();
  const [ practitioner, setPractitioner ] = useState<PractitionerInterface>();
  const [ location, setLocation ] = useState<LocationInterface>();
  const [ organization, setOrganization ] = useState<OrganizationInterface>();
  const [ assets, setAssets ] = useState<AssetInterface[]>( [] );
  // const [ reputationServices, setReputationServices ] = useState<ReputationServiceInterface[]>( [] );
  // const [ countryCode, setCountryCode ] = useState( 'US' );

  useEffect( () => {
    ( async () => {
      if( !feedbackId || isLoaded ) return;
      const { recipient, practitioner, location, organization, assets,
        //reputationServices, countryCode
      } = ( await getFeedback( feedbackId ) ) || {};
      if( !recipient ) return;
      setIsLoaded( true );
      setRecipient( recipient );
      setPractitioner( practitioner );
      setLocation( location );
      setOrganization( organization )
      setAssets( assets || [] );
      // setReputationServices( reputationServices || [] );
      // setCountryCode( countryCode || 'US' );
    } )()
  }, [ feedbackId ] );

  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader, disableCharities } = useMemo( () => {
    if( !organization ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, disableCharities } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, disableCharities };
  }, [ assets, organization ] );

  const hrefNext = disableCharities ? useHref( 'reviews' ) : useHref( 'nonprofits' );


  if( !( recipient && organization && location && practitioner ) ) return null;

  return (
    <Box sx={{
      padding: { xs: 0, md: '0 1.5rem' },
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: { xs: '1rem', md: '1.2rem' },
      backgroundColor: '#f9fafb',
      fontFamily: fontBody,

    }}>
      <Helmet>
        <title>Feedback - {location.name}</title>
        <meta name="description" content={`Feedback on ${ location.name }`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main className={styles.main} style={{ fontFamily: fontBody }} >

        <TopSection {...{ location, organization, fontHeader, colorPrimary, isXSmall }} />

        <Grid item key="content">
          <Typography variant='h6' className={styles.description}
            sx={{ margin: '0.5em 0' }}
          >
            EXCELLENCE IN HEALTH CARE
          </Typography>

          <p>{recipient.firstName}{ /* ({recipientType}) */}, </p>
          <p>Thank you, again, for visiting us at <b>{location.name}</b> and practitioner <b>{practitioner.fullName}</b>.</p>
          <p>
            To help others, please leave a review of our practice on public websites such as Google and Yelp.
          </p>
          {!disableCharities &&
            <>
              <p>
                As a token of our gratitude for your feedback, we will make donation to a non-profit
                of your choice. On the next page you can select your favorite charity.

              </p>

              {/* <h4>
              WERE THERE ANY PROBLEMS WITH YOUR VISIT?
              </h4>
              <p>
              If there is an issue or you have had any difficulty working with us, please
              give us the chance to make it right before your evaluation.
              Our phone number is <a href={`tel:${ location.telephone }`}>{location.telephone}</a> or
              email us at <a href={`mailto:${ location.email }`}>{location.email}</a>.
              </p>
              <p>
              We sincerely care about your experience and proving you with the best
              healthcare.
              </p>
            */}
              {/* <p>
              As a token of our gratitude for your feedback, we will make donation to a non-profit
              of your choice.
              On the next page you can select your favorite charity.
              </p> */}

              <Grid item>
                <NonProfitTicker />
              </Grid>

              <Typography variant="h6" align="center" mt={2}>
                GRATITUDE & CHARITY MAKE THE WORLD BETTER
              </Typography>
            </>
          }
        </Grid>


        <Grid item container key="nextButton" justifyContent="flex-end" sx={{ marginTop: '2em' }}>
          <Button variant="contained" size="large" href={hrefNext} color="success" fullWidth={isXSmall}>
            Next
          </Button>
        </Grid>
      </main>

      <PageFooter organization={organization} />

    </Box>
  )
}

export interface TopSectionProps {
  location?: LocationInterface;
  organization?: OrganizationInterface;
  fontHeader?: string;
  colorPrimary?: string;
  isXSmall?: boolean;
}

export const TopSection: FC<TopSectionProps> = props => {
  const { location, organization, fontHeader, colorPrimary, isXSmall } = props;
  return (
    <>
      <Grid item container direction="column"
        sx={{
          // padding: { xs: '0 -2em', md: 'inherit' },
          // width: { xs: '120%', md: '100%' },
        }}
      >
        <Link
          href='/'
          sx={{
            margin: { xs: '0 -2.5em', md: 'inherit' },
            width: { xs: '120%', md: '100%' },
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            sx={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: { xs: '0.5rem 3.0rem', md: '0.5rem 1.0rem' },
              fontWeight: 'bold', //'600',
              fontSize: 'large',
              fontSmooth: 'always',
            }}
          >
            AIC Analog Concierge &trade;
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </Link>
      </Grid>

      <Link
        key='name'
        href='/' sx={{ width: '100%', textDecorationLine: 'none' }}>
        <Typography variant='h1'
          align={ /* isXSmall ? 'center' : */ 'left'}
          style={{
            fontFamily: !isXSmall ? fontHeader : undefined,
            // backgroundColor: colorPrimary,
            color: colorPrimary, // 'white',
            width: '100%',
            padding: '0.5rem 0',
            fontWeight: 'bold', //'600',
            fontSize: 'xx-large',
            fontSmooth: 'always',
          }}
        >
          {location?.name || organization?.name}
        </Typography>
      </Link>
    </>
  );
}
