/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { FC, useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetInterface } from './types';
import { Toolbar, Box, Button, TextField, Typography, useMediaQuery, Divider } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHref } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { PageFooter } from './components/page-footer';
import { addFeedbackMessage, FeedbackFormData, getFeedback } from './profiles';
import styles from './styles/Home.module.css';
import { LocationInterface, OrganizationInterface, OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';
import { FeedbackParams, TopSection } from './Feedback';

export type FeedbackFormParams = {
  feedbackId: string;
  severity?: string;
};

export const FeedbackForm: FC = () => {
  const { feedbackId = '62a24459ffecdb6fdbe7ccfc', severity = 'notAtAll' } = useParams<FeedbackFormParams>();
  const navigate = useNavigate();

  // const { setNotify } = useOutletContext<ProfOutletContext>();
  // const data = useRouteLoaderData( 'prof' ) as ProfileData;
  // const { recipients } = data || {};
  // const { countryCode = 'US', onSubmit, data: defaultValues } = props;
  const { control, getValues, handleSubmit, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<FeedbackFormData>();

  const onSubmit = useCallback( async ( data: FeedbackFormData ) => {
    await addFeedbackMessage( feedbackId, { ...data, severity } );
    navigate( 'thank-you' );
  }, [ feedbackId ] );

  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ recipient, setRecipient ] = useState<RecipientInterface>();
  const [ practitioner, setPractitioner ] = useState<PractitionerInterface>();
  const [ location, setLocation ] = useState<LocationInterface>();
  const [ organization, setOrganization ] = useState<OrganizationInterface>();
  const [ assets, setAssets ] = useState<AssetInterface[]>( [] );
  // const [ reputationServices, setReputationServices ] = useState<ReputationServiceInterface[]>( [] );
  // const [ countryCode, setCountryCode ] = useState( 'US' );

  useEffect( () => {
    ( async () => {
      if( !feedbackId || isLoaded ) return;
      const { recipient, practitioner, location, organization, assets,
        //reputationServices, countryCode
      } = ( await getFeedback( feedbackId ) ) || {};
      if( !recipient ) return;
      setIsLoaded( true );
      setRecipient( recipient );
      setPractitioner( practitioner );
      setLocation( location );
      setOrganization( organization )
      setAssets( assets || [] );
      // setReputationServices( reputationServices || [] );
      // setCountryCode( countryCode || 'US' );
    } )()
  }, [ feedbackId ] );

  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader } = useMemo( () => {
    if( !organization ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader };
  }, [ assets, organization ] );

  if( !( recipient && organization && location && practitioner ) ) return null;

  return (
    <Box sx={{
      padding: { xs: 0, md: '0 1.5rem' },
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: { xs: '1rem', md: '1.2rem' },
      backgroundColor: '#f9fafb',
      fontFamily: fontBody,

    }}>
      <Helmet>
        <title>Form - Feedback - {location.name}</title>
        <meta name="description" content={`Feedback on ${ location.name }`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main className={styles.main} style={{ fontFamily: fontBody }} >

        <TopSection {...{ location, organization, fontHeader, colorPrimary, isXSmall }} />

        <Divider />

        <Typography
          align='left'
          sx={{
            margin: '2rem 0',
            width: '100%',
            fontStyle: 'italic',
            fontSize: '120%',
            color: colorPrimary || 'primary',
          }}
          color='inherit'
        >
          What was missing or disappointing in your experience with us?
        </Typography>

        <form
          style={{
            width: '100%',
            marginBottom: '1rem',
          }}
        >

          <Controller
            name='message'
            control={control}
            rules={{
              maxLength: {
                value: 3000,
                message: 'Maximum of 3000 characters'
              }
            }}
            render={( { field } ) => (
              <TextField
                {...field}
                inputRef={field.ref}
                margin='dense'
                label='Feedback'
                fullWidth
                variant='filled'
                multiline
                minRows={6}
                error={!!errors?.message?.message}
                helperText={errors?.message?.message}
              />
            )}
          />

        </form>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant='contained'
            disabled={!isDirty || isValidating || isSubmitting || isSubmitted}
            onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
            sx={{ fontSize: '90%' }}
            color='success'
          >
            Submit
          </Button>
        </Toolbar>
      </main>

      <PageFooter organization={organization} />

    </Box>

  );
}

