/*eslint @typescript-eslint/no-unused-vars: 'off'*/
import { SentimentVeryDissatisfied } from '@mui/icons-material';
import { ArrowBackIosNew, SentimentDissatisfied, SentimentNeutral, SentimentSatisfied, SentimentSatisfiedAlt } from '@mui/icons-material';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHref, useNavigate, useParams } from 'react-router-dom';
import { getCommonTagId } from './components/AmenityChoices';
import { NonProfitTicker } from './components/NonProfitTicker';
import { PageFooter } from './components/page-footer';
import { getFeedback } from './profiles';
import styles from './styles/Home.module.css';
import { AssetInterface, LocationInterface, OrganizationInterface, OrganizationStyling, PractitionerInterface, RecipientInterface } from './types';

export type FeedbackParams = { feedbackId: string };

export const FeedbackRecommend: FC = () => {
  const { feedbackId = '62a24459ffecdb6fdbe7ccfc' } = useParams<FeedbackParams>();
  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const hrefVeryLikely = useHref( 'recommend' );
  const hrefSlightly = useHref( 'form/slightly' );
  const hrefNotAtAll = useHref( 'form/notAtAll' );
  const navigate = useNavigate();

  const [ isLoaded, setIsLoaded ] = useState( false );
  const [ recipient, setRecipient ] = useState<RecipientInterface>();
  const [ practitioner, setPractitioner ] = useState<PractitionerInterface>();
  const [ location, setLocation ] = useState<LocationInterface>();
  const [ organization, setOrganization ] = useState<OrganizationInterface>();
  const [ assets, setAssets ] = useState<AssetInterface[]>( [] );
  // const [ reputationServices, setReputationServices ] = useState<ReputationServiceInterface[]>( [] );
  // const [ countryCode, setCountryCode ] = useState( 'US' );

  useEffect( () => {
    ( async () => {
      if( !feedbackId || isLoaded ) return;
      const { recipient, practitioner, location, organization, assets,
        //reputationServices, countryCode
      } = ( await getFeedback( feedbackId ) ) || {};
      if( !recipient ) return;
      setIsLoaded( true );
      setRecipient( recipient );
      setPractitioner( practitioner );
      setLocation( location );
      setOrganization( organization )
      setAssets( assets || [] );
      // setReputationServices( reputationServices || [] );
      // setCountryCode( countryCode || 'US' );
    } )()
  }, [ feedbackId ] );

  useEffect( () => {
    if( location && !location.locationEnabledForReviewGating ) {
      navigate( 'recommend' ); // proceed past the gating of this page
    }
  }, [ location ] );


  const { logoAsset, logoTitle, colorPrimary, fontBody, fontHeader, disableCharities } = useMemo( () => {
    if( !organization ) return {};
    // const { amenityTags } = organization;
    const { iconStyle = 'default', amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, disableCharities } = ( organization || {} ) as unknown as OrganizationStyling;
    const iconProps = {
      // htmlColor: [ 'default', 'primary', 'secondary' ].includes( iconStyle ) ? undefined : iconStyle,
      '& svg': {
        color: [ 'primary', 'secondary' ].includes( iconStyle )
          ? ( iconStyle == 'primary' ? colorPrimary : colorSecondary )
          : 'inherit',
        fontSize: '2.25rem', // 'large',
      }
    };
    const logoAsset = ( assets ).find( asset => ( asset.tags || [] ).includes( getCommonTagId( 'square' ) ) );
    const logoTitle = [ organization.name, logoAsset?.subject || 'logo' ].join( ' ' );
    return { logoAsset, logoTitle, iconProps, amenityLayout, colorPrimary, colorSecondary, fontBody, fontHeader, disableCharities };
  }, [ assets, organization ] );

  if( !( recipient && organization && location && practitioner ) ) return null;

  return (
    <Box sx={{
      padding: { xs: 0, md: '0 1.5rem' },
      maxWidth: '960px',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: { xs: '1rem', md: '1.2rem' },
      backgroundColor: '#f9fafb',
      fontFamily: fontBody,

    }}>
      <Helmet>
        <title>Recommend - Feedback - {location.name}</title>
        <meta name="description" content={`Feedback on ${ location.name }`} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <main className={styles.main} style={{ fontFamily: fontBody }} >

        <TopSection {...{ location, organization, fontHeader, colorPrimary, isXSmall }} />

        <Grid container key="content" >
          <Grid item>
            <Typography variant='h6'
              paragraph
              align='center'
              sx={{
                margin: '0.5em 0',
              }}
            >
              EXCELLENCE IN HEALTH CARE
            </Typography>

            <p>{recipient.firstName}{ /* ({recipientType}) */}, </p>
            <p>Thank you for visiting us at <b>{location.name}</b> and practitioner <b>{practitioner.fullName}</b>.</p>
            <p>
              To help us provide the best medical care, would you mind evaluating your
              experience with our staff and facility?
            </p>

            <Divider />

            <Typography
              align='center'
              sx={{
                margin: '2rem 0',
                width: '100%',
                fontStyle: 'italic',
                fontSize: '120%',
              }}
              color={colorPrimary || 'primary'}
            >
              How likely are you to recommend our services to a friend or colleague?
            </Typography>
          </Grid>

          <Grid item container key="buttons"
            justifyContent="space-between"
            alignItems='flex-start'
            rowSpacing={4}
            direction='row'
            xs={12}
            sm={8}
            sx={{
              margin: '0 auto',
              // border: '1px solid purple',
            }}
          >
            <Grid item key='1' xs={12}//  sm={'auto'}
              sx={{
                // border: '1px solid red',
              }}
            >
              <Button
                variant="outlined"
                size="large"
                href={hrefVeryLikely}
                startIcon={<SentimentSatisfiedAlt fontSize='inherit' />}
                fullWidth
                sx={{
                  fontSize: '100%',
                  color: colorPrimary || 'primary',
                  borderColor: colorPrimary || 'primary',
                  '& .MuiButton-startIcon .MuiSvgIcon-root': {
                    fontSize: '36px',
                  }
                }}
              >
                <span style={{ width: '8rem', textAlign: 'center' }}>
                  Very Likely
                </span>
              </Button>
            </Grid>
            <Grid item key='2' xs={12} // sm={'auto'}
            >
              <Button
                variant="outlined"
                size="large"
                href={hrefSlightly}
                startIcon={<SentimentNeutral />}
                fullWidth
                sx={{
                  fontSize: '100%',
                  color: colorPrimary || 'primary',
                  borderColor: colorPrimary || 'primary',
                  '& .MuiButton-startIcon .MuiSvgIcon-root': {
                    fontSize: '36px',
                  }
                }}
              >
                <span style={{ width: '8rem', textAlign: 'center' }}>
                  Slighty
                </span>
              </Button>
            </Grid>
            <Grid item key='3' xs={12} // sm={'auto'}
            >
              <Button
                variant="outlined"
                size="large"
                href={hrefNotAtAll}
                startIcon={<SentimentVeryDissatisfied />}
                fullWidth
                sx={{
                  fontSize: '100%',
                  color: colorPrimary || 'primary',
                  borderColor: colorPrimary || 'primary',
                  '& .MuiButton-startIcon .MuiSvgIcon-root': {
                    fontSize: '36px',
                  }
                }}
              >
                <span style={{ width: '8rem', textAlign: 'center' }}>
                  Not at all
                </span>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </main >

      <PageFooter organization={organization} />

    </Box >
  )
}

export interface TopSectionProps {
  location?: LocationInterface;
  organization?: OrganizationInterface;
  fontHeader?: string;
  colorPrimary?: string;
  isXSmall?: boolean;
}

export const TopSection: FC<TopSectionProps> = props => {
  const { location, organization, fontHeader, colorPrimary, isXSmall } = props;
  return (
    <>
      <Grid item container direction="column"
        sx={{
          // padding: { xs: '0 -2em', md: 'inherit' },
          // width: { xs: '120%', md: '100%' },
        }}
      >
        <Link
          href='/'
          sx={{
            margin: { xs: '0 -2.5em', md: 'inherit' },
            width: { xs: '120%', md: '100%' },
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            sx={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: { xs: '0.5rem 3.0rem', md: '0.5rem 1.0rem' },
              fontWeight: 'bold', //'600',
              fontSize: 'large',
              fontSmooth: 'always',
            }}
          >
            AIC Analog Concierge &trade;
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </Link>
      </Grid>

      <Link
        key='name'
        href='/' sx={{ width: '100%', textDecorationLine: 'none' }}>
        <Typography variant='h1'
          align={ /* isXSmall ? 'center' : */ 'left'}
          style={{
            fontFamily: !isXSmall ? fontHeader : undefined,
            // backgroundColor: colorPrimary,
            color: colorPrimary, // 'white',
            width: '100%',
            padding: '0.5rem 0',
            fontWeight: 'bold', //'600',
            fontSize: 'xx-large',
            fontSmooth: 'always',
          }}
        >
          {location?.name || organization?.name}
        </Typography>
      </Link>
    </>
  );
}
