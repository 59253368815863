import { ArrowBackIosNew } from '@mui/icons-material';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Link as MuiLink, Radio, RadioGroup, TextField, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { Outlet, ScrollRestoration, useLoaderData, useNavigate } from 'react-router-dom';
import { SimpleConfirmSnackbar, SimpleErrorSnackbar } from './AppointmentContainer';
import { PageFooter } from './components/page-footer';
import { LocBreadcrumbs } from './Locs';
import { LocationsData, ProfileSearchFormResponse, searchProfiles, searchProfilesOption } from './profiles';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LanguageSwitcher from './LanguageSwitcher';
import { OrganizationContext } from './ProfileContainer';

dayjs.extend( utc );
// import { validate as validateEmail } from 'isemail';

export interface ProfileSearchContext extends Partial<ProfileSearchFormData>, LocationsData, OrganizationContext {
  setHeader: Dispatch<SetStateAction<string>>;
}

export const ProfileSearch: FC = () => {
  const data = useLoaderData() as LocationsData;
  const { organization, assets } = data;

  const [ header, setHeader ] = useState( '' );
  const [ title, setTitle ] = useState( '' );  // From context ??
  const [ error, setError ] = useState( '' );
  const [ notify, setNotify ] = useState( '' );
  const navigate = useNavigate();

  const theme = useTheme();
  const isXSmall = useMediaQuery( theme.breakpoints.down( 'sm' ) );
  const isSmall = useMediaQuery( theme.breakpoints.down( 'md' ) );
  const isMedium = useMediaQuery( theme.breakpoints.down( 'lg' ) );

  const { colorPrimary, colorSecondary, fontBody, fontHeader } = useMemo( () => {
    const {
      colorPrimary = theme.palette.primary.main,
      colorSecondary = theme.palette.secondary.main,
      fontBody = 'inherit',
      fontHeader = 'inherit',
    } = ( organization || {} );
    return { colorPrimary, colorSecondary, fontBody, fontHeader };
  }, [ assets, organization ] );

  useEffect( () => setTitle( organization.name ), [ organization.name ] );
  useEffect( () => setHeader( 'New Appointment - Patient Lookup' ), [] );

  const [ search, setSearch ] = useState<ProfileSearchFormData | undefined>();
  const [ response, setResponse ] = useState<ProfileSearchFormResponse | undefined>();
  const hasOptions = useMemo( () => Object.keys( response || {} ).length > 0, [ response ] );
  const [ option, setOption ] = useState<string | undefined>();

  const onSubmit = useCallback( async ( data: ProfileSearchFormData ) => {
    console.log( JSON.stringify( data ) );
    setSearch( data );
    const response = await searchProfiles( data );
    setResponse( response );
  }, [ setResponse, setSearch ] );

  const context: ProfileSearchContext = {
    ...data,
    setHeader, setTitle, setNotify, setError,
    isXSmall, isSmall, isMedium,
    colorPrimary, colorSecondary, fontBody, fontHeader,
    ...search,
  };

  if( !data ) return null;

  return (
    <Box
      id='profile'
      sx={{
        padding: { xs: '0 1.5rem' },
        maxWidth: { xs: '100%', md: '960px' },

        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: { xs: '1rem', md: '1.2rem' },
        backgroundColor: '#f9fafb',
        fontFamily: fontBody,
        '& .MuiListItemText-root .MuiTypography-root': {
          fontFamily: fontBody,
        },

      }}>

      <Helmet>
        <title>
          {header}
        </title>
        { /* <meta name='description' content={`Profile on ${ recipient.firstName }`} /> */}
        <link rel='icon' href='/favicon.ico' />
      </Helmet>


      { /* <SeoTags title={pageTitle} organization={organization} logoAsset={logoAsset} /> */}
      <ScrollRestoration />

      <SimpleConfirmSnackbar
        open={!!notify}
        onClose={() => { setNotify( '' ) }}
        message={notify}
      />
      <SimpleErrorSnackbar
        open={!!error}
        onClose={() => { setError( '' ) }}
        message={error}
      />

      <Grid item container direction="row"
        sx={{
          margin: { xs: '0 -1.3em', md: 'inherit' },
          width: { xs: '120%', md: 'inherit' },
          // border: '1px solid blue',
        }}
        justifyContent='space-between'
      >
        <Grid item xs={5}
          sx={{
            // border: '1px solid red',

          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              color: colorPrimary,
              // color: 'white',
              width: '100%',
              padding: '0rem 1.0rem',
              fontWeight: 'bold', //'600',
              fontSize: 'medium',
              fontSmooth: 'always',
            }}
            className='translated'
          >
            AIC Analog Concierge &trade;
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}>
              <ArrowBackIosNew />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item  >
          <LanguageSwitcher />
        </Grid>
        <MuiLink
          href='/locs'
          sx={{
            width: '100%',
            textDecorationLine: 'none',
          }}
        >
          <Typography variant='h1'
            align={isXSmall ? 'center' : 'left'}
            style={{
              fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
              backgroundColor: colorPrimary,
              color: 'white',
              width: '100%',
              padding: '0.5rem 1.0rem',
              fontWeight: 'bold', //'600',
              fontSize: 'x-large',
              fontSmooth: 'always',
            }}
            className='translated'
          >
            {title}
            { /* transparent button used to match height of Location header bar */}
            <IconButton sx={{ color: 'transparent' }}> <ArrowBackIosNew /> </IconButton>
          </Typography>
        </MuiLink>
      </Grid >


      <LocBreadcrumbs />


      <Box>
        <Typography
          style={{
            fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
            width: '100%',
            padding: '0.5rem 0',
            fontSize: 'x-large',
            fontSmooth: 'always',
          }}
          gutterBottom
        >
          New Appointment - Patient Lookup
        </Typography>
        {!response &&
          <ProfileSearchForm
            data={{}}
            onSubmit={onSubmit}
          />
        }

        {response && hasOptions &&
          <>
            <Typography
              style={{
                // fontFamily: fontHeader, // !isXSmall ? fontHeader : undefined,
                // width: '100%',
                padding: '0.5rem 0',
                fontSize: 'large',
                fontSmooth: 'always',
              }}
              gutterBottom
            >
              To access your profile, we will send a confirmation link to an email address or phone number (via sms/text) that you have on file with our office.
            </Typography>

            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Pick a phone or email to send link
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={undefined}
                onChange={( _e, value: string | undefined ) => setOption( value )}
              >
                {Object.entries( response ).map( ( [ value, label ] ) => (
                  <FormControlLabel value={value} control={<Radio />} label={label} />
                ) )}
                <FormControlLabel value={''} control={<Radio />} label={'None of these'} />
              </RadioGroup>
              <Button

                variant='contained'
                disabled={!option}
                disableRipple
                sx={{
                  marginBottom: 2,
                }}
                onClick={async () => {
                  const { profileId } = option && ( await searchProfilesOption( option ) ) || {};
                  if( !profileId ) {
                    setResponse( undefined );
                    return;
                  }
                  navigate( `/app/profiles/${ profileId }/appointments/schedule` );
                }}
              >
                Send
              </Button>
              <Button
                variant='outlined'
                // disabled={!option}
                onClick={() => setResponse( undefined )}
                disableRipple
              >
                Cancel
              </Button>
            </FormControl>
          </>
        }
        {response && !hasOptions &&
          <Outlet context={context} />
        }
      </Box>
      <PageFooter {...{ organization }} />

    </Box >


  );
}


export interface ProfileSearchFormData {
  name: string;
  birthDate: Date;
}

export interface ProfileSearchFormProps {
  data: Partial<ProfileSearchFormData>;
  countryCode?: string;
  onSubmit: ( data: ProfileSearchFormData ) => void; // TODO
  onClose?: () => void;
}

export const ProfileSearchForm: FC<ProfileSearchFormProps> = ( props ) => {
  const { countryCode = 'US', onSubmit, data: defaultValues = {} } = props;
  const { control, getValues, handleSubmit, formState: { errors, isDirty, isValidating, isValid, isSubmitted, isSubmitting } } = useForm<ProfileSearchFormData>( { defaultValues } );
  const navigate = useNavigate();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      <Box
        component='form'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          '& .MuiTextField-root': {
            width: '20rem'
          },
          '& .MuiToolbar-root': {
            marginTop: '1rem',
          },
        }}
      >

        <Controller
          name='name'
          control={control}
          rules={{
            required: 'Name is required',
            pattern: {
              value: /\S{2}\s+\S{2}/,
              message: 'First and last names are required',
            },
          }}
          render={( { field } ) => (
            <TextField
              {...field}
              inputRef={field.ref}
              autoFocus
              required
              margin='normal'
              label='Name'
              placeholder='First Last'
              // fullWidth
              variant='filled'
              error={!!errors?.name?.message}
              helperText={errors?.name?.message}
            />
          )}
        />

        <Controller
          name='birthDate'
          control={control}
          rules={{
            required: 'Birth date is required',
            //  valueAsDate: true,
          }}
          render={( { field } ) => {
            const { value, ...rest } = field;
            return (
              <DateField
                {...rest}
                value={new dayjs.Dayjs( value )}
                timezone='UTC'
                onChange={( e ) => e && field.onChange( e )}
                inputRef={field.ref}
                required
                margin='normal'
                label='Birth date'
                // fullWidth
                variant='filled'
              // error={!!errors?.birthDate?.message}
              // helperText={errors?.birthDate?.message}
              />
            )
          }}
        />

        <Toolbar >
          <Button
            variant='contained'
            disabled={!isDirty || isValidating || isSubmitting
              // || isSubmitted
            }
            onClick={handleSubmit( onSubmit, ( v ) => { console.log( errors, v ) } )}
            disableRipple
          >
            Search
          </Button>
        </Toolbar>
      </Box>

    </LocalizationProvider >
  );
}
