import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, useMemo, useCallback } from 'react';
import { FeedbackInterface, LocationInterface, PractitionerInterface, PractitionerReputationServiceInterface, ReputationServiceInterface } from '../types';
import debounce from 'lodash/debounce';

const caredash = '/caredash.png';
const google = '/google.png';
const googleMaps = '/google.png';
const healthcare6 = '/healthcare6.png';
const healthgrades = '/healthgrades.png';
const npino = '/npino.png';
const sharecare = '/sharecare.png';
const yelp = '/yelp.png';

const apiServer = process.env.REACT_APP_API_SERVER || '';
const apiPath = process.env.REACT_APP_API_PATH || '/api/v1/data';

const reputationServiceLogo: Record<string, string> = {
  caredash,
  google,
  googleMaps,
  healthcare6,
  healthgrades,
  npino,
  sharecare,
  yelp,
};

export interface ReviewButtonsProps {
  feedback: FeedbackInterface;
  subject: PractitionerInterface | LocationInterface;
  reputationServices: ReputationServiceInterface[];
}

export const ReviewButtons: FC<ReviewButtonsProps> = ( props ) => {
  const { subject, feedback, reputationServices } = props;

  return (
    <>
      <Typography variant="h5" sx={{ marginTop: '0em', marginBottom: '0.5em' }} >
        {/* Reviews for {'fullName' in subject ? `practitioner: ${ subject.fullName }` : `location: ${ subject.name }`} */}
        Review: {'fullName' in subject ? `${ subject.fullName }` : `${ subject.name }`}
      </Typography>
      <Grid container direction="row" key="reviewLinks"
        justifyContent="flex-start" spacing={2}
        alignItems="center"
        sx={{ marginBottom: '2.0em' }}
      >
        {reputationServices.map( ( p ) => (
          <ReviewButton key={p.id} service={p} feedback={feedback} />
        ) )
        }
      </Grid>
    </>
  );
}

export interface ReviewButtonProps {
  feedback: FeedbackInterface;
  service: ReputationServiceInterface;
}

export const ReviewButton: FC<ReviewButtonProps> = ( { feedback, service } ) => {

  const { id, name, referralUrl, url } = service;
  const image = reputationServiceLogo[ id ];

  const reviewUrl = ( referralUrl || url || '' );

  const recordClick = useCallback( debounce( async () => {
    await fetch( `${ apiServer }${ apiPath }/feedback/review/clicked`, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify( { t: feedback._id, url } ),
    } );
  }, 10000, { leading: true, trailing: false } ), [] )

  const handleClick = async () => {
    console.log( 'clicked', name );
    document.open( reviewUrl.replace( /writereview/, 'reviews' ), '_blank', 'noopener' ); // 
    await recordClick();
  };

  if( !service || !url || !image ) return null;

  return (
    <>
      <Grid item xs={8} sm={4} sx={{ border: '0px solid blue', height: '10em' }}>
        <Card
          sx={{
            height: '100%',
            borderRadius: '5%',
          }}
          elevation={4}
        >
          <CardActionArea
            onClick={handleClick}
            sx={{ width: '100%', height: '100%' }}
          >
            <CardMedia
              component='img'
              image={image}
              alt={`Review link for ${ service.name }`}
              sx={{ maxWidth: '90%', maxHeight: '90%', margin: 'auto' }}

            />
          </CardActionArea>
        </Card>
      </Grid>
    </>
  );
}
